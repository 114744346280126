<template>
  <div class="h-100 d-flex venues-list flex-column py-3">
    <b-container>
      <p class="text-white">
        <small>
          Choisissez votre "ville" ou votre "adresse, ville" ou votre "code
          postal" ci-dessus puis renseignez le plat recherché. Vos résultats de
          recherche apparaîtront
          <span class="highlight">surlignés en jaune</span> suivant qu'ils se
          trouvent dans le Menu Du Jour ou la Carte des plats
        </small>
      </p>
    </b-container>
    <b-form id="search-menu" class="p-3" @submit.prevent="reset">
      <b-container>
        <b-input-group>
          <b-input
            ref="inputSearchMenu"
            v-model="search"
            size="lg"
            placeholder="Que voulez-vous manger ?"
            @focus="handleBtn()"
            @click="handleBtn()"
          ></b-input>
          <b-input-group-append>
            <b-button variant="white" @click="resetSearch()">
              <font-awesome-icon icon="times" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <div v-if="showBtn" id="formSubmitBtn" class="d-flex justify-content-center mt-3">
          <b-button variant="success" block class="w-full font-weight-bold text-uppercase" type="submit">Rechercher</b-button>
        </div>
      </b-container>
    </b-form>
    
    <b-row v-show="search.length" no-gutters class="venues-list pt-3">
      <b-col v-for="venue in venuesByMenu" :key="venue.id" cols="12">
        <b-container class="p-0 position-relative">
          <b-link
            :to="{ path: 'venue/' + venue.id, query: { search: search } }"
            append
            :disabled="$route.params.id != null"
            style="z-index: 11"
            class="position-absolute w-100 h-100"
          >
          </b-link>
          <div
            v-if="hasActiveMenu(venue)"
            class="bg-white shadow-lg py-3 inner border border-bottom-0 mx-3"
          >
            <mdj-menu :menu="activeMenus(venue)[0]" :search="search" />
          </div>
          <div
            v-if="hasPermanentMenu(venue)"
            class="bg-primary inner text-white text-uppercase text-center py-2 mx-3"
          >
            <span
              class="highlight text-black p-2 position-relative"
              style="z-index: 12; cursor:pointer"
              @click.prevent="showPermanentMenus(venue)"
            >
              Voir la carte et les menus
            </span>
          </div>

          <venue-list-item
            :venue="venue"
            :search="search"
            :display-menu="false"
            :hide-header="true"
            :advertiser-day="advertiserDay"
            view="list"
          />
        </b-container>
      </b-col>
    </b-row>
    <b-modal
      v-if="currentPermanentMenus.length"
      id="menus-modal"
      scrollable
      size="xl"
      hide-footer
      title="Extrait de la carte des plats"
      @hidden="
        currentPermanentMenus = []
        currentVenue = null
      "
    >
      <div v-touch:swipe.top="onSwipeTop" v-touch-options="{swipeTolerance: 30, touchHoldTolerance: 500}">
        <b-link
          :to="{ path: 'venue/' + currentVenue.id, query: { search: search } }"
          append
          class="text-decoration-none"
          @click="$bvModal.hide('menus-modal')"
        >
          <mdj-permanent-menu
            v-for="permanentMenu in currentPermanentMenus"
            :key="permanentMenu.id"
            :menu="permanentMenu"
            :search="search"
          />
          <div class="text-center">
            <b-button variant="primary">
              VOIR LA FICHE DE CE RESTAURANT ET SA CARTE COMPLÈTE
            </b-button>
          </div>
        </b-link>
        <b-link
          class="d-block text-center mt-2"
          @click="$bvModal.hide('menus-modal')"
        >
          <small>Revenir aux résultats de recherche</small>
        </b-link>
      </div>
    </b-modal>

    <portal to="content">
      <transition name="swipe">
        <router-view />
      </transition>
    </portal>
    <fit-height-loader
      v-if="$apollo.queries.venuesByMenu.loading"
      class="position-absolute"
      show
      style="z-index: 200; left:0; background-color: rgba(255, 255, 255, 0.5)"
    />
    </b-container>
  </div>
</template>
<script>
import { mapState } from "vuex"
import VENUES_BY_MENU from "@/graphql/VenuesByMenu.gql"
import FitHeightLoader from "@/components/FitHeightLoader"
import ADVERTISER_DAY from "@/graphql/AdvertiserDay.gql"
import VenueListItem from "@//components/VenueListItem"
import MDJPermanentMenu from "@/components/PermanentMenu"
import MDJMenu from "@/components/Menu"
export default {
  components: {
    FitHeightLoader,
    VenueListItem,
    "mdj-menu": MDJMenu,
    "mdj-permanent-menu": MDJPermanentMenu
  },
  data() {
    return {
      search: this.$route.query.search || "",
      previousRadius: this.radius,
      currentPermanentMenus: [],
      currentVenue: null,
      targetTop: null,
      showBtn: true,
      debug: {}
    }
  },
  apollo: {
    venuesByMenu: {
      query: VENUES_BY_MENU,
      skip: true
    },
    advertiserDay: {
      query: ADVERTISER_DAY
    }
  },
  computed: {
    ...mapState({
      coordinate: state => state.coordinate,
      radius: state => state.radius,
      place: state => state.city
    })
  },
  watch: {
    place: {
      handler: function() {
        if (this.place) {
          this.reset()
        }
      }
    },
    radius: function() {
      if (this.place) {
        this.reset()
      }
    },
  },
  created() {
    this.previousRadius = this.radius
    this.$store.commit("radius", this.$route.query.radius || 100)
  },
  mounted() {
    const target = document.getElementById("content")
    target.addEventListener("scroll", this.onScroll)
    this.targetTop = document.getElementById('search-menu').getBoundingClientRect().top - 52
  },
  beforeDestroy() {
    this.$store.commit("radius", this.previousRadius)
    const target = document.getElementById("content")
    target.classList.remove("sticky")
    target.removeEventListener("scroll", this.onScroll)
    target.querySelector(' .venues-list .venues-list ').paddingTop = ''
  },
  methods: {
    onScroll(evt) {
      const target = document.getElementById("content")
      if (target.scrollTop > this.targetTop) {
         target.classList.add("sticky")
         target.querySelector(' .venues-list .venues-list ').style.paddingTop = `${this.targetTop}px` 
         this.showBtn = false
      } else {
         target.classList.remove("sticky")
         target.querySelector(' .venues-list .venues-list ').paddingTop = ''
         this.showBtn = true
      }
    },
    reset() {
      if (this.search.length >= 2) {
        const target = document.getElementById("content")
        target.scrollTop = this.targetTop + 1
        this.$apollo.queries.venuesByMenu.setVariables(this.variables())
        this.$apollo.queries.venuesByMenu.skip = false
        this.$apollo.queries.venuesByMenu.refetch().finally(() => {
          this.$refs["inputSearchMenu"].blur()
        })
      } else {
        this.venuesByMenu = []
      }
    },
    variables() {
      const vars = {
        coordinate: {
          lat: this.coordinate.lat,
          lng: this.coordinate.lng
        },
        radius: this.radius,
        search: this.search,
        place: this.place,
        source: this.$route.query.source || "webapp"
      }
      return vars
    },
    hasActiveMenu(venue) {
      return this.activeMenus(venue).length
    },
    hasPermanentMenu(venue) {
      return this.permanentMenus(venue).length
    },
    activeMenus(venue) {
      return venue.menus.filter(m => m.publishedAt !== null)
    },
    permanentMenus(venue) {
      return venue.menus.filter(m => m.publishedAt === null)
    },
    showPermanentMenus(venue) {
      this.currentPermanentMenus = this.permanentMenus(venue)
      this.currentVenue = venue
      this.$nextTick(() => {
        this.$bvModal.show("menus-modal")
      })
    },
    handleBtn() {
      const target = document.getElementById("content")
      if (target.classList.contains('sticky')) {
        this.showBtn =  true
      }
    },
    onSwipeTop() {
      let elt = document.getElementById('menus-modal___BV_modal_body_')
      if (elt.scrollTop + elt.offsetHeight > elt.scrollHeight) {
       this.$bvModal.hide("menus-modal")
      }
    },
    resetSearch() {
      this.search = ''
      this.$refs['inputSearchMenu'].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.venues-list {
  background: #42464a;
}

#search-menu {
  .input-group-append {
    .btn {
      border-color: #ced4da;
      border-left: 0;
      &:focus {
        outline: none;
        box-shadow: none;
        background: white;
      }
    }
  }
  .input-group {
    .form-control:focus {
      box-shadow: none;
      border-color: #ced4da;
      border-right: 0;
    }
  }
}
</style>
